$primary: #2F4F4F;
$body-bg: #f4f4f4;
$font-size-base: 1rem;

$h7-font-size: $font-size-base * 0.85;
$font-sizes: (
  7: $h7-font-size
);

@import "bootstrap";

body {
  background-color: #eaeaea !important;
}

.btn-primary, .btn-primary:hover {
  color: #ffffff;
}

.vh-94 {
  height: 94vh;
}
.zi-1 {
  z-index: 1;
}
.zi-2 {
  z-index: 2;
}

.spinner-container {
  z-index: 10;
  position: absolute;
  background-color: white;
  opacity: 0.8;
  width: 98%;
  height: 82%;
  z-index: 10;
}
.card-header {
  background-color: #ebebe0;
}

.home_button {
  width: 9.5rem;
  height: 9.5rem;
  display: flex;
  flex-direction: column;
  background-color: var(--bs-primary);
  color: white;
  border-radius: var(--bs-border-radius);
  padding: 1rem 0.5rem;
  margin: 1.25rem;
}

.home_button,
.home_button:hover,
.home_button:focus {
  box-shadow: 0.25rem 0.25rem 0.25rem #00000055 !important;
}
.home_button div {
  text-align: center;
}
.home_button div:first-child {
  font-size: 3rem;
}
.home_buttons_div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
    // margin: auto;
    height: 50%;
    align-items: center;
}
